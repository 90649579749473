<template>
  <div class="row px-md-4">
    <div class="col-12 col-sm-12">
      <h2 class="main-title">Cost Centres</h2>
    </div>
    <div class="col-12 col-sm-12">
      <div class="card">
        <div class="row">
          <div
            class="col-12 col-sm-12 d-flex flex-column flex-md-row align-items-center justify-content-between"
          >
            <div class="d-flex align-items-center">
              <h2>
                Cost Centres
              </h2>
              <i
                id="cost-centres-info"
                class="far fa-info-circle ml-2 color-blue"
                @click="tooltips.costCentre = !tooltips.costCentre"
              ></i>
              <b-tooltip
                :show.sync="tooltips.costCentre"
                target="cost-centres-info"
                placement="right"
              >
                Use Cost Centers to associate individual lines to specific
                departments or billing codes. This will allow Finance and
                Accounting departments to review your telecom investments per
                department and distribute department level reports to department
                managers. To send specific departmental reports to departments
                leads please visit the Managers section of Expertel iQ.
              </b-tooltip>
            </div>
            <div class="box-alert-message">
              You have
              <strong>{{ unused_lines.lines.length }} lines</strong> that are
              not assigned to a cost centre.
              <button class="link" @click.prevent="showUnLines()">
                Review
              </button>
            </div>
          </div>
        </div>
        <div class="row mt-3">
          <div
            class="col-12 col-sm-12 d-flex align-items-center justify-content-between"
          >
            <button class="btn button-expertel" @click="showAddCostCentre">
              Add/Edit a Cost Centre
            </button>
            <button
              href="#"
              class="link color-orange"
              @click="showInstructions"
            >
              Step by step instructions
            </button>
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-sm-12">
            <hr class="h-3" />
          </div>
        </div>
        <div class="row" v-if="mobile == false">
          <div class="col-12 col-sm-12 col-md-6">
            <table class="table-3 table-hover pointer-hover">
              <thead>
                <tr>
                  <th>Cost Centre</th>
                  <th class="text-center text-md-left"># of Users</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  :class="{ active: costActive == data.centre_id }"
                  v-for="(data, key) in cost_centre_data.cost_centres"
                  :key="key"
                  @click="showAssignedDetail(data.centre_id)"
                >
                  <td>
                    {{ data.centre_name }}
                  </td>
                  <td class="text-center text-md-left">
                    {{ data.count }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="col-12 col-sm-12 col-md-6">
            <div
              class="table-fixed-header h-600-px"
              v-if="used_lines.length > 0"
            >
              <table class="table-2">
                <thead>
                  <tr>
                    <th class="color-blue">Phone Number</th>
                    <th class="color-blue">Line User</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(data, key) in used_lines" :key="key">
                    <td class="color-blue font-bold">
                      {{ data.phone | phoneNumber }}
                    </td>
                    <td class="color-blue font-bold">
                      {{ data.name }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div
            v-if="noLinesAssigned === true && used_lines.length <= 0"
            class="h-600-px d-flex align-items-center justify-content-center w-100"
          >
            <h5
              class="font-circular-book font-weight-normal text-center pt-40px"
            >
              There are no lines assigned to any Cost Centre.
            </h5>
          </div>
        </div>
        <div class="row" v-else>
          <grid-loader
            class="expertel-loader"
            color="#ff502d"
            :loading="loading"
            :size="20"
            sizeUnit="px"
          ></grid-loader>

          <div
            class="col-12 col-sm-12"
            v-for="(data, key) in cost_centre_data.cost_centres"
            :key="key"
          >
            <b-navbar-toggle
              b-navbar-toggle
              :target="`cost-centre-${key}`"
              class="title-card col-12 col-sm-12 d-flex justify-content-between"
              @click="showAssignedDetail(data.centre_id)"
            >
              <template #default="{ expanded }">
                <span>{{ data.centre_name }}</span>
                <span>{{ data.count }}</span>
                <i class="far fa-angle-up" v-if="expanded"></i>
                <i class="far fa-angle-down" v-else></i>
              </template>
            </b-navbar-toggle>
            <b-collapse
              :id="`cost-centre-${key}`"
              :accordion="`cost-centre`"
              role="tabpanel"
            >
              <div class="row">
                <div class="col-12 col-sm-12">
                  <grid-loader
                    class="expertel-loader"
                    color="#ff502d"
                    :loading="loadingCentre"
                    :size="20"
                    sizeUnit="px"
                  ></grid-loader>
                  <div class="table-fixed-header" v-if="used_lines.length > 0">
                    <table class="table-2">
                      <thead>
                        <tr>
                          <th class="color-blue">Phone Number</th>
                          <th class="color-blue">Line User</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="(data, key) in used_lines" :key="key">
                          <td class="color-blue font-bold">
                            {{ data.phone | phoneNumber }}
                          </td>
                          <td class="color-blue font-bold">
                            {{ data.name }}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </b-collapse>
          </div>

          <div
            v-if="noLinesAssigned === true && used_lines.length <= 0"
            class="h-600-px d-flex align-items-center justify-content-center w-100"
          >
            <h5
              class="font-circular-book font-weight-normal text-center pt-40px"
            >
              There are no lines assigned to any Cost Centre.
            </h5>
          </div>
        </div>
      </div>
    </div>
    <div class="col-12 col-sm-12 mt-4 mb-40px">
      <div class="card">
        <div class="row">
          <div
            class="col-12 col-sm-12 d-block d-md-flex align-items-md-start align-items-md-center justify-content-between"
          >
            <h2 class="d-flex align-items-center">
              Report Recipients
              <i
                id="report-recipients-info"
                class="far fa-info-circle ml-2 mt-1 color-blue"
                @click="tooltips.recipients = !tooltips.recipients"
              ></i>
              <b-tooltip
                :show.sync="tooltips.recipients"
                target="report-recipients-info"
                placement="right"
              >
                Send the full Cost Center report via email to people within your
                organization. To send specific departmental reports to
                departments leads please visit the Managers section of Expertel
                iQ. Note: to automate the emailing of reports please visit the
                Report Distribution page, found in the Settings tab.
              </b-tooltip>
            </h2>
            <button class="btn button-expertel" @click="showAddRecipient">
              <i class="far fa-plus mr-2"></i> Add Recipient
            </button>
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-sm-12 col-md-12">
            <div class="recipients-cost-centre-container">
              <div
                class="recipient-cost-centre"
                v-for="(data, key) in cost_centre_data.recipients"
                :key="key"
                @click="deleteRecipient(data.id)"
              >
                <strong>{{ data.name }}</strong> | {{ data.email }}
                <i class="far fa-times ml-2"></i>
              </div>
            </div>
          </div>
          <div class="col-12 col-sm-12 col-md-6"></div>
        </div>
      </div>
    </div>

    <!-- [[ ADD INSTRUCTIONS MODAL ]] -->
    <transition name="fade">
      <div
        class="modal-expertel container-fluid"
        v-if="show_instrictions === true"
      >
        <div class="modal-container">
          <div class="header-modal">
            <button class="close-modal" @click="showInstructions()">
              <i class="fa fa-times" aria-hidden="true"></i>
            </button>
          </div>
          <div class="body-modal">
            <h3 class="text-center mb-3">
              <strong>Instructions to add/edit Cost Centres</strong>
            </h3>
            <p class="text-center color-gray">
              <strong class="color-blue">Step 1:</strong>
              <button class="link color-gray" @click="showAddCostCentre()">
                Download
              </button>
              the current Cost Centres list.
            </p>
            <p class="text-center color-gray">
              <strong class="color-blue">Step 2:</strong> Add phone numbers in
              the first column, and department name/billing code in second
              column.
            </p>
            <p class="text-center color-gray">
              <strong class="color-blue">Step 3:</strong>
              <button class="link color-gray" @click="showAddCostCentre()">
                Upload
              </button>
              the updated list.
            </p>
            <p class="text-center color-gray">
              <strong class="color-blue">Step 4:</strong> Refresh the page on
              your browser to see the updated changes.
            </p>
          </div>
        </div>
      </div>
    </transition>
    <!-- [[ ADD INSTRUCTIONS MODAL ]] -->

    <!-- [[ ADD ADD/EDIT COST CENTRE MODAL ]] -->
    <transition name="fade">
      <div
        class="modal-expertel container-fluid"
        v-if="add_costCentre === true"
      >
        <div class="modal-container">
          <div class="header-modal">
            <button class="close-modal" @click="showAddCostCentre()">
              <i class="fa fa-times" aria-hidden="true"></i>
            </button>
          </div>
          <div class="body-modal">
            <h3 class="text-center">
              <strong>Add/Edit Cost Centre List</strong>
            </h3>
            <p class="text-center my-4 color-gray">
              <strong class="color-blue">Download</strong>
              <br />
              To include a new cost centre or edit an existing one first
              download the current Cost Centre list. <br />
              <a
                :href="cost_centre_data.files.cost_centre_url"
                class="button-expertel py-3 mt-3"
                >Download Current List</a
              >
            </p>
            <hr class="my-4" />
            <p class="text-center mt-4 color-gray">
              <strong class="color-blue">Upload</strong>
              <br />
              After editing your file, please upload the updated list.
            </p>
            <div class="upload-file">
              <input
                id="file"
                type="file"
                @change.prevent="onChange()"
                ref="file"
              />
              <button class="btn button-outline-expertel-gray">
                Choose file
              </button>
              <p class="ml-3 mb-0 color-gray" v-if="this.file == ''">
                No file chosen
              </p>
              <p class="ml-3 mb-0 color-gray" v-else>{{ file.name }}</p>
            </div>
            <p class="text-center ">
              <a
                href="#"
                class="button-expertel py-3 mt-3"
                @click.prevent="uploadCostCentreList()"
                >Upload List</a
              >
            </p>
          </div>
        </div>
      </div>
    </transition>
    <!-- [[ ADD ADD/EDIT COST CENTRE MODAL ]] -->

    <!-- [[ ADD RECIPIENT MODAL ]] -->
    <transition name="fade">
      <div class="modal-expertel container-fluid" v-if="add_recipient === true">
        <div class="modal-container">
          <div class="header-modal">
            <button class="close-modal" @click="showAddRecipient()">
              <i class="fa fa-times" aria-hidden="true"></i>
            </button>
          </div>
          <div class="body-modal">
            <h3 class="text-center mb-3"><strong>Add a Recipient</strong></h3>
            <p class="text-center color-gray mb-3">
              Recipients will receive the Cost Centre report.
            </p>
            <form
              action="#"
              @submit.prevent="showAddRecipient"
              class="d-flex flex-column"
            >
              <input
                class="input-expertel"
                type="text"
                placeholder="Name"
                v-model="name"
              />
              <input
                class="input-expertel"
                type="text"
                placeholder="Email"
                v-model="email"
              />
              <label
                for="Email Error"
                class="text-danger"
                v-if="emailValidator !== ''"
              >
                {{ emailValidator }}</label
              >
              <button
                type="submit"
                class="btn button-expertel mt-4 py-3"
                @click.prevent="addNewRecipient()"
              >
                Add Recipient
              </button>
            </form>
          </div>
        </div>
      </div>
    </transition>
    <!-- [[ ADD RECIPIENT MODAL ]] -->

    <!-- [[ UNASSIGNED LINES MODAL ]] -->
    <transition name="fade">
      <div class="modal-expertel container-fluid" v-if="show_unLines === true">
        <div class="modal-container medium">
          <div class="header-modal">
            <button class="close-modal" @click="showUnLines()">
              <i class="fa fa-times" aria-hidden="true"></i>
            </button>
          </div>
          <div class="body-modal">
            <h3
              class="d-flex align-items-center font-circular-book font-weight-normal mb-3"
            >
              Unassigned Lines
              <i
                id="unassigned-lines-info"
                class="far fa-info-circle ml-2 mt-1 color-blue"
                style="font-size:16px;"
              ></i>
              <b-tooltip target="unassigned-lines-info" placement="right">
                Unassigned Lines
              </b-tooltip>
            </h3>
            <p class="mb-0 color-gray">
              The following lines are not assigned to a Cost Centre
            </p>
            <div class="row">
              <div class="col-12 col-sm-12 px-0">
                <hr class="h-3 mt-3 mb-4" />
              </div>
            </div>
            <div class="row">
              <div class="col-12 col-sm-12 px-0">
                <div class="table-fixed-header xh-60vh">
                  <table class="table-2">
                    <thead>
                      <tr>
                        <th class="color-blue">User Name</th>
                        <th class="color-blue">Phone Number</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(item, key) in unused_lines.lines" :key="key">
                        <td>{{ item.name }}</td>
                        <td>{{ item.cc_phone_number | phoneNumber }}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div
                  v-if="unused_lines.lines.length > 10"
                  class="text-center mt-3"
                >
                  <i class="far fa-chevron-down" style="font-size: 26px;"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>
    <!-- [[ UNASSIGNED LINES MODAL ]] -->
  </div>
</template>
<style lang="scss" scoped>
.box-alert-message {
  font-size: 16px;
}
.link {
  font-size: 16px;
}
</style>
<script>
export default {
  props: ["login_data"],
  data() {
    return {
      cost_centre_data: [],
      used_lines: [],
      unused_lines: [],
      add_recipient: false,
      add_costCentre: false,
      show_instrictions: false,
      show_unLines: false,
      file: "",
      name: "",
      email: "",
      centresIdAssigned: "",
      emailValidator: "",
      costActive: 0,
      noLinesAssigned: true,
      tooltips: {
        costCentre: false,
        recipients: false
      },
      loading: true,
      loadingCentre: true,
      mobile: false
    };
  },
  created() {
    if (window.outerWidth < 767) {
      this.mobile = true;
    }
    this.loading = true;
    const self = this;

    //VALIDATE THAT THE USER IS LOGGED IN
    if (this.login_data !== undefined || this.login_data.success === false) {
      if (!this.login_data.success) {
        this.$router.push("/");
      }
    } else {
      this.$router.push("/");
    }

    //GET THE COST CENTRE DATA AND SAVE IT IN [cost_centre_data]
    this.axios({
      method: "get",
      url: `https://api.expertel.ca/api/v1/client/me/cost_centres`,
      responseType: "json",
      headers: {
        Authorization: "Bearer " + self.login_data.token
      }
    })
      .then(async function(response) {
        self.cost_centre_data = response.data;
        self.cost_centre_data.cost_centres.forEach(function(value_data) {
          if (value_data.count > 0) {
            self.noLinesAssigned = false;
            return false;
          }
        });
      })
      //.catch(error => this.makeToast("Error",error.message, "danger"));
      .catch(function (error) {
        let msg = error.message
        if(error.response.status===403)
        {
          msg = "You need to upgrade your billing plan."
        }
        //.catch(error => (error.response.status===403)?this.makeToast("ERROR","You need to upgrade your billing plan.", "danger"):this.makeToast("ERROR",error, "danger"));
          self.makeToast("ERROR",msg, "danger")
      });

    //GET unassigned list
    this.axios({
      method: "get",
      url: `https://api.expertel.ca/api/v1/cost_centres/0`,
      responseType: "json",
      headers: {
        Authorization: "Bearer " + self.login_data.token
      }
    })
      .then(async function(response) {
        self.unused_lines = response.data;
      })
      .catch(error => this.makeToast("ERROR",error.message, "danger"));

    this.loading = false;
  },
  methods: {
    showAssignedDetail(centre_id) {
      const self = this;
      this.costActive = centre_id;
      self.centresIdAssigned = centre_id;
      self.getLines();
    },
    async getLines() {
      const self = this;
      this.used_lines = [];
      this.loadingCentre = true;

      await this.axios({
        method: "get",
        url:
          `https://api.expertel.ca/api/v1/cost_centres/` +
          self.centresIdAssigned,
        responseType: "json",
        headers: {
          Authorization: "Bearer " + self.login_data.token
        }
      })
        .then(function(response) {
          response.data.lines.map(function(value) {
            self.used_lines.push({
              name: `${value.name}`,
              phone: value.phone_number
            });
          });
          self.loadingCentre = false;
        })
        .catch(error => this.makeToast("ERROR",error.message, "danger"));
    },
    showAddRecipient() {
      //SHOW OR NOT MODAL
      this.add_recipient = !this.add_recipient;
    },
    showAddCostCentre() {
      //SHOW OR NOT MODAL
      this.add_costCentre = !this.add_costCentre;
    },
    showInstructions() {
      //SHOW OR NOT MODAL
      this.show_instrictions = !this.show_instrictions;
    },
    showUnLines() {
      //SHOW OR NOT MODAL
      this.show_unLines = !this.show_unLines;
    },
    makeToast(title, message, variant = null) {
      this.$bvToast.toast(`${message}`, {
        title: title,
        autoHideDelay: 5000,
        appendToast: true,
        variant: variant
      });
    },
    onChange() {
      this.file = this.$refs.file.files[0];
    },
    uploadCostCentreList() {
      const self = this;
      this.loading = true;

      //FROMDATA TO SEND
      let formData = new FormData();
      formData.append("list", this.file);

      this.axios({
        method: "post",
        url: `https://api.expertel.ca/api/v1/client/me/cost_centres/upload`,
        responseType: "json",
        data: formData,
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: "Bearer " + self.login_data.token
        }
      })
        .then(function(response) {
          if (response.data.success == false) {
            self.makeToast("ERROR",response.data.errors, "danger");
            self.loading = false;
          } else {
            self.makeToast(
              "Uploaded successfully",
              "List of Cost Centres uploaded successfully",
              "success"
            );
            self.loading = false;
            self.file = "";
          }
        })
        .catch(
          error => self.makeToast("ERROR",error.message, "danger"),
          (self.loading = false)
        );
    },
    addNewRecipient() {
      const self = this;

      // eslint-disable-next-line
      const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (re.test(self.email)) {
        this.emailValidator = "";

        this.loading = true;
        //FROMDATA TO SEND
        let formData = new FormData();
        formData.append("name", self.name);
        formData.append("email", self.email);

        this.axios({
          method: "post",
          url: `https://api.expertel.ca/api/v1/client/me/cost_centres/recipients`,
          responseType: "json",
          data: formData,
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: "Bearer " + self.login_data.token
          }
        })
          .then(function(response) {
            if (response.data.success == false) {
              self.makeToast(response.data.message, "danger");
              self.loading = false;
            } else {
              self.makeToast("Ok", "Recipient added successfully", "success");
              self.getRecipientsData();
              self.name = "";
              self.email = "";
              self.loading = false;
            }
          })
          .catch(
            error => self.makeToast("ERROR",error.message, "danger"),
            (self.loading = false)
          );
      } else {
        this.emailValidator = "Please enter a valid email address";
      }
    },
    async getRecipientsData() {
      const self = this;
      await this.axios({
        method: "get",
        url: `https://api.expertel.ca/api/v1/client/me/cost_centres`,
        responseType: "json",
        headers: {
          Authorization: "Bearer " + self.login_data.token
        }
      })
        .then(async function(response) {
          self.cost_centre_data.recipients = response.data.recipients;
        })
        .catch(error => this.makeToast("ERROR",error.message, "danger"));
    },

    deleteRecipient(id) {
      const self = this;

      this.$swal({
        title: "Are you sure?",
        text: "Are you sure to delete the item?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!"
      }).then(result => {
        if (result.isConfirmed) {
          this.axios({
            method: "delete",
            url:
              `https://api.expertel.ca/api/v1/client/me/cost_centres/recipients/` +
              id,
            responseType: "json",
            headers: {
              Authorization: "Bearer " + self.login_data.token
            }
          })
            .then(async function(response) {
              if (response.data.success == false) {
                self.makeToast(response.data.message, "danger");
                self.loading = false;
              } else {
                self.makeToast("Ok", "Recipient was delete it", "success");
                self.getRecipientsData();
              }
            })
            .catch(error => this.makeToast("ERROR",error.message, "danger"));
        }
      });
    }
  }
};
</script>
